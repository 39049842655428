.review-rating-outer {
  background: $white;
  max-width: 440px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 5px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

  }

  .rating-start {
    font-size: 30px;
    padding: 20px 0;

    i {
      margin: 0px 5px;
    }

    .ic-star-filled:before {
      color: #EDC534;
    }

  }
  .btn-submit{
    .btn-primary{
      margin-top: 15px;
      min-width: auto;
      padding: 0px 30px;
    }
  }
}