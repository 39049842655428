.p-10-5px {
    padding: 10px 5px;
}

.add-application {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
}

.br-top {
    border-top: 1px solid $border-color;
    padding-top: 20px;
    margin-top: 20px;
}

.m-inherit {
    margin: inherit;
}

.go-home-view-lead {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-app-metro-ui {
    .custom-control-label {
        display: block !important;
    }
}
