@font-face {
    font-family: 'dc';
    src:  url('../fonts/dc.eot?k1u0od');
    src:  url('../fonts/dc.eot?k1u0od#iefix') format('embedded-opentype'),
      url('../fonts/dc.ttf?k1u0od') format('truetype'),
      url('../fonts/dc.woff?k1u0od') format('woff'),
      url('../fonts/dc.svg?k1u0od#dc') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="ic-"], [class*=" ic-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'dc' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ic-pending_apps .path1:before {
    content: "\e9c2";
    color: rgb(0, 0, 0);
  }
  .ic-pending_apps .path2:before {
    content: "\e9c3";
    margin-left: -0.92578125em;
    color: rgb(0, 0, 0);
  }
  .ic-pending_apps .path3:before {
    content: "\e9c4";
    margin-left: -0.92578125em;
    color: rgb(0, 0, 0);
  }
  .ic-pending_apps .path4:before {
    content: "\e9c5";
    margin-left: -0.92578125em;
    color: rgb(0, 0, 0);
  }
  .ic-pending_apps .path5:before {
    content: "\e9c6";
    margin-left: -0.92578125em;
    color: rgb(0, 0, 0);
  }
  .ic-pending_apps .path6:before {
    content: "\e9c7";
    margin-left: -0.92578125em;
    color: rgb(255, 255, 255);
  }
  .ic-pending_apps .path7:before {
    content: "\e9c8";
    margin-left: -0.92578125em;
    color: rgb(0, 0, 0);
  }
  .ic-pending_apps .path8:before {
    content: "\e9c9";
    margin-left: -0.92578125em;
    color: rgb(0, 0, 0);
  }
  .ic-in_progress:before {
    content: "\e9ca";
  }
  .ic-lost1 .path1:before {
    content: "\e9cb";
    color: rgb(0, 0, 0);
  }
  .ic-lost1 .path2:before {
    content: "\e9cf";
    margin-left: -1.0400390625em;
    color: rgb(0, 0, 0);
  }
  .ic-lost1 .path3:before {
    content: "\e9d0";
    margin-left: -1.0400390625em;
    color: rgb(0, 0, 0);
  }
  .ic-lost1 .path4:before {
    content: "\e9d1";
    margin-left: -1.0400390625em;
    color: rgb(0, 0, 0);
  }
  .ic-lost1 .path5:before {
    content: "\e9d2";
    margin-left: -1.0400390625em;
    color: rgb(0, 0, 0);
  }
  .ic-lost1 .path6:before {
    content: "\e9d3";
    margin-left: -1.0400390625em;
    color: rgb(255, 255, 255);
  }
  .ic-lost1 .path7:before {
    content: "\e9d4";
    margin-left: -1.0400390625em;
    color: rgb(0, 0, 0);
  }
  .ic-lost1 .path8:before {
    content: "\e9d5";
    margin-left: -1.0400390625em;
    color: rgb(0, 0, 0);
  }
  .ic-payment_requested:before {
    content: "\e9bd";
  }
  .ic-payment_completed:before {
    content: "\e9be";
  }
  .ic-payment_dealer:before {
    content: "\e9bf";
  }
  .ic-payment_financier:before {
    content: "\e9c0";
  }
  .ic-payment_partially_paid:before {
    content: "\e9c1";
  }
  .ic-configuration:before {
    content: "\e9b6";
  }
  .ic-lost .path1:before {
    content: "\e9ad";
    color: rgb(0, 0, 0);
  }
  .ic-lost .path2:before {
    content: "\e9ae";
    margin-left: -1.2724609375em;
    color: rgb(255, 255, 255);
  }
  .ic-lost .path3:before {
    content: "\e9af";
    margin-left: -1.2724609375em;
    color: rgb(0, 0, 0);
  }
  .ic-lost .path4:before {
    content: "\e9b0";
    margin-left: -1.2724609375em;
    color: rgb(0, 0, 0);
  }
  .ic-disbursed .path1:before {
    content: "\e9b1";
    color: rgb(0, 0, 0);
  }
  .ic-disbursed .path2:before {
    content: "\e9b2";
    margin-left: -0.9599609375em;
    color: rgb(255, 255, 255);
  }
  .ic-disbursed .path3:before {
    content: "\e9b3";
    margin-left: -0.9599609375em;
    color: rgb(0, 0, 0);
  }
  .ic-disbursed .path4:before {
    content: "\e9b4";
    margin-left: -0.9599609375em;
    color: rgb(0, 0, 0);
  }
  .ic-do:before {
    content: "\e9ac";
  }
  .ic-softapproved .path1:before {
    content: "\e96d";
    color: rgb(0, 0, 0);
  }
  .ic-softapproved .path2:before {
    content: "\e96e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-softapproved .path3:before {
    content: "\e96f";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-softapproved .path4:before {
    content: "\e970";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-softapproved .path5:before {
    content: "\e971";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-softapproved .path6:before {
    content: "\e972";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-softapproved .path7:before {
    content: "\e973";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-softapproved .path8:before {
    content: "\e974";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-reassign:before {
    content: "\e975";
  }
  .ic-po:before {
    content: "\e976";
  }
  .ic-pending-application .path1:before {
    content: "\e977";
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path2:before {
    content: "\e978";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path3:before {
    content: "\e979";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path4:before {
    content: "\e97a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path5:before {
    content: "\e97b";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path6:before {
    content: "\e97c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-pending-application .path7:before {
    content: "\e97d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path8:before {
    content: "\e97e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path9:before {
    content: "\e97f";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-pending-application .path10:before {
    content: "\e980";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-listview .path1:before {
    content: "\e981";
    color: rgb(255, 255, 255);
  }
  .ic-listview .path2:before {
    content: "\e982";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-listview .path3:before {
    content: "\e983";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-listview .path4:before {
    content: "\e984";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-listview .path5:before {
    content: "\e985";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-listview .path6:before {
    content: "\e986";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-import:before {
    content: "\e987";
  }
  .ic-history:before {
    content: "\e988";
  }
  .ic-gridview .path1:before {
    content: "\e989";
    color: rgb(255, 255, 255);
  }
  .ic-gridview .path2:before {
    content: "\e98a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-gridview .path3:before {
    content: "\e98b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-gridview .path4:before {
    content: "\e98c";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-gridview .path5:before {
    content: "\e98d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-gridview .path6:before {
    content: "\e98e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-gridview .path7:before {
    content: "\e98f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-gridview .path8:before {
    content: "\e990";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path1:before {
    content: "\e991";
    color: rgb(0, 0, 0);
  }
  .ic-financier .path2:before {
    content: "\e992";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path3:before {
    content: "\e993";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path4:before {
    content: "\e994";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path5:before {
    content: "\e995";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path6:before {
    content: "\e996";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-financier .path7:before {
    content: "\e997";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path8:before {
    content: "\e998";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path9:before {
    content: "\e999";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-financier .path10:before {
    content: "\e99a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-edit:before {
    content: "\e99b";
  }
  .ic-document .path1:before {
    content: "\e99c";
    color: rgb(0, 0, 0);
  }
  .ic-document .path2:before {
    content: "\e99d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-document .path3:before {
    content: "\e99e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-document .path4:before {
    content: "\e99f";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-document .path5:before {
    content: "\e9a0";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-document .path6:before {
    content: "\e9a1";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-document .path7:before {
    content: "\e9a2";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-document .path8:before {
    content: "\e9a3";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-document .path9:before {
    content: "\e9a4";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-document .path10:before {
    content: "\e9a5";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-callverified .path1:before {
    content: "\e9a6";
    color: rgb(0, 0, 0);
  }
  .ic-callverified .path2:before {
    content: "\e9a7";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .ic-callverified .path3:before {
    content: "\e9a8";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-callverified .path4:before {
    content: "\e9a9";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-booster:before {
    content: "\e9aa";
  }
  .ic-application:before {
    content: "\e9ab";
  }
  .ic-info-icon1:before {
    content: "\e960";
  }
  .ic-delete-icn:before {
    content: "\e96b";
  }
  .ic-zipfile:before {
    content: "\e9cc";
  }
  .ic-upload:before {
    content: "\e9cd";
  }
  .ic-pdf:before {
    content: "\e9ce";
  }
  .ic-notification:before {
    content: "\e9bb";
    color: #fff;
  }
  .ic-file-icon:before {
    content: "\e962";
  }
  .ic-password:before {
    content: "\e963";
  }
  .ic-otp .path1:before {
    content: "\e964";
    color: rgb(255, 255, 255);
  }
  .ic-otp .path2:before {
    content: "\e965";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-otp .path3:before {
    content: "\e966";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-otp .path4:before {
    content: "\e967";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .ic-mobile:before {
    content: "\e968";
  }
  .ic-email1:before {
    content: "\e969";
  }
  .ic-expand:before {
    content: "\e96c";
    color: #fff;
  }
  .ic-dialer:before {
    content: "\e95f";
  }
  .ic-Call-history:before {
    content: "\e961";
  }
  .ic-survey:before {
    content: "\e9b5";
  }
  .ic-incentive .path1:before {
    content: "\e9b7";
    color: rgb(0, 0, 0);
  }
  .ic-incentive .path2:before {
    content: "\e9b8";
    margin-left: -1.2919921875em;
    color: rgb(255, 255, 255);
  }
  .ic-incentive .path3:before {
    content: "\e9b9";
    margin-left: -1.2919921875em;
    color: rgb(0, 0, 0);
  }
  .ic-incentive .path4:before {
    content: "\e9ba";
    margin-left: -1.2919921875em;
    color: rgb(0, 0, 0);
  }
  .ic-remark-notepad:before {
    content: "\e9bc";
  }
  .ic-currency:before {
    content: "\e96a";
  }
  .ic-star-empty:before {
    content: "\e9d8";
    color: #d5d5d5;
  }
  .ic-error:before {
    content: "\e900";
  }
  .ic-replay:before {
    content: "\e901";
  }
  .ic-call:before {
    content: "\e902";
  }
  .ic-chat:before {
    content: "\e903";
  }
  .ic-email:before {
    content: "\e904";
  }
  .ic-location_onplaceroom:before {
    content: "\e905";
  }
  .ic-alternate_email:before {
    content: "\e906";
  }
  .ic-add:before {
    content: "\e907";
  }
  .ic-clearclose:before {
    content: "\e908";
  }
  .ic-content_copy:before {
    content: "\e909";
  }
  .ic-createmode_editedit:before {
    content: "\e90a";
  }
  .ic-filter_list:before {
    content: "\e90b";
  }
  .ic-remove:before {
    content: "\e90c";
  }
  .ic-save:before {
    content: "\e90d";
  }
  .ic-save_alt:before {
    content: "\e90e";
  }
  .ic-access_alarms:before {
    content: "\e90f";
  }
  .ic-access_timequery_builderschedule:before {
    content: "\e910";
  }
  .ic-brightness_highbrightness_7:before {
    content: "\e911";
  }
  .ic-gps_not_fixedlocation_searching:before {
    content: "\e912";
  }
  .ic-attach_file:before {
    content: "\e913";
  }
  .ic-insert_drive_file:before {
    content: "\e914";
  }
  .ic-insert_photoimagephoto:before {
    content: "\e915";
  }
  .ic-folder:before {
    content: "\e916";
  }
  .ic-keyboard_arrow_down:before {
    content: "\e917";
  }
  .ic-keyboard_arrow_left:before {
    content: "\e918";
  }
  .ic-keyboard_arrow_right:before {
    content: "\e919";
  }
  .ic-keyboard_arrow_up:before {
    content: "\e91a";
  }
  .ic-color_lenspalette:before {
    content: "\e91b";
  }
  .ic-crop:before {
    content: "\e91c";
  }
  .ic-panorama_fish_eyeradio_button_unchecked:before {
    content: "\e91d";
  }
  .ic-remove_red_eyevisibility:before {
    content: "\e91e";
  }
  .ic-add_a_photo:before {
    content: "\e91f";
  }
  .ic-directions_car:before {
    content: "\e920";
  }
  .ic-arrow_back:before {
    content: "\e921";
  }
  .ic-arrow_drop_down:before {
    content: "\e922";
  }
  .ic-arrow_drop_down_circle:before {
    content: "\e923";
  }
  .ic-arrow_drop_up:before {
    content: "\e924";
  }
  .ic-arrow_forward:before {
    content: "\e925";
  }
  .ic-cancel:before {
    content: "\e926";
  }
  .ic-check:before {
    content: "\e927";
  }
  .ic-fullscreen:before {
    content: "\e928";
  }
  .ic-fullscreen_exit:before {
    content: "\e929";
  }
  .ic-menu:before {
    content: "\e92a";
  }
  .ic-keyboard_control:before {
    content: "\e92b";
  }
  .ic-more_vert:before {
    content: "\e92c";
  }
  .ic-refresh:before {
    content: "\e92d";
  }
  .ic-arrow_back_ios:before {
    content: "\e92e";
  }
  .ic-arrow_forward_ios:before {
    content: "\e92f";
  }
  .ic-notifications:before {
    content: "\e930";
  }
  .ic-person:before {
    content: "\e931";
  }
  .ic-person_add:before {
    content: "\e932";
  }
  .ic-share:before {
    content: "\e933";
  }
  .ic-check_box:before {
    content: "\e934";
  }
  .ic-check_box_outline_blank:before {
    content: "\e935";
  }
  .ic-radio_button_checked:before {
    content: "\e936";
  }
  .ic-stargrade:before {
    content: "\e937";
  }
  .ic-bookmarkturned_in:before {
    content: "\e938";
  }
  .ic-delete:before {
    content: "\e939";
  }
  .ic-favorite:before {
    content: "\e93a";
  }
  .ic-favorite_outline:before {
    content: "\e93b";
  }
  .ic-search:before {
    content: "\e93c";
  }
  .ic-settings:before {
    content: "\e93d";
  }
  .ic-date_range:before {
    content: "\e93e";
  }
  .ic-cd_R:before {
    content: "\e93f";
  }
  .ic-cd-Dealer2:before {
    content: "\e940";
  }
  .ic-cd-fuel:before {
    content: "\e941";
  }
  .ic-cd-kilometer:before {
    content: "\e942";
  }
  .ic-cd-owner:before {
    content: "\e943";
  }
  .ic-cd-Transmission:before {
    content: "\e944";
  }
  .ic-cd-year:before {
    content: "\e945";
  }
  .ic-colors:before {
    content: "\e946";
  }
  .ic-compare-car:before {
    content: "\e947";
  }
  .ic-india-no:before {
    content: "\e948";
  }
  .ic-new-car:before {
    content: "\e949";
  }
  .ic-rc-file:before {
    content: "\e94a";
  }
  .ic-subscription:before {
    content: "\e94b";
  }
  .ic-cd-featured:before {
    content: "\e94c";
  }
  .ic-cd-un-featured:before {
    content: "\e94d";
  }
  .ic-rupees-line:before {
    content: "\e94e";
  }
  .ic-excel-file:before {
    content: "\e94f";
  }
  .ic-image-file:before {
    content: "\e950";
  }
  .ic-pdf-file:before {
    content: "\e951";
  }
  .ic-word-file:before {
    content: "\e952";
  }
  .ic-file-history:before {
    content: "\e95b";
  }
  .ic-whatsapp:before {
    content: "\e953";
  }
  .ic-blur_circular:before {
    content: "\e95a";
  }
  .ic-blur_on:before {
    content: "\e954";
  }
  .ic-rotate_left:before {
    content: "\e955";
  }
  .ic-rotate_right:before {
    content: "\e956";
  }
  .ic-stars:before {
    content: "\e957";
  }
  .ic-filter_list_alt:before {
    content: "\e95c";
  }
  .ic-peson-blacklist:before {
    content: "\e958";
  }
  .ic-peson-listed:before {
    content: "\e959";
  }
  .ic-engine:before {
    content: "\e95d";
  }
  .ic-bump-up:before {
    content: "\e95e";
  }
  .ic-star-filled:before {
    content: "\e9d9";
  }
  