.agent-detail-table {
    table {
        tr {
            th,
            td {
                width: 12%;
                &:nth-child(2) {
                    width: 8%;
                }
                &:nth-child(3) {
                    width: 14%;
                }
                &:nth-child(4) {
                    width: 46%;
                }
                &:nth-child(5) {
                    width: 6%;
                }
                &:nth-child(6) {
                    width: 8%;
                }
            }
            td {
                vertical-align: top;
                &:nth-child(4) {
                    border-bottom: 1px solid $border-color;
                }
                &:nth-child(5) {
                    border-bottom: 1px solid $border-color;
                }
                &:nth-child(6) {
                    border-bottom: 1px solid $border-color;
                }

                &:nth-child(3) {
                    border-right: 1px solid $border-color;
                }

                &:nth-child(5) {
                    border-left: 1px solid $border-color;
                }
            }
        }
        .sub-table {
            tr {
                border-top: 1px solid $border-color;
                &:nth-child(1) {
                    border-top: 0px;
                }
                th {
                    padding-top: 0px;
                    background: #e3e7f1;
                }
                border-bottom: 0px;
                th,
                td {
                    padding-bottom: 0px;
                    &:first-child {
                        padding-left: 0px;
                        width: 40%;
                    }

                    &:last-child {
                        padding-right: 0px;
                        width: 60%;
                    }
                }
            }
        }
        .sub-table-sub-data {
            tr {
                border-top: 0px;
                border-bottom: 0px;
                td,
                th {
                    padding-top: 0px;
                    &:first-child {
                        width: 60%;
                        padding-top: 0px;
                    }

                    &:last-child {
                        padding-top: 0px;
                        width: 40%;
                    }
                }
            }
        }
        tbody {
            tr {
                &:hover {
                    background: transparent;
                }
            }
        }
    }

    .action-btn {
        display: flex;
        ul {
            display: flex;
        }
        li {
            height: 32px;
            width: 32px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            cursor: pointer;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .more-txt-count {
        height: 21px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $accent-color;
        font-size: 11px;
        font-weight: 500;
        width: fit-content;
        border: 1px solid $accent-color;
        border-radius: 5px;
    }
    .inactive-txt {
        color: #e02020;
    }
}

.Add-agnet-popup {
    .modal-main {
        border-radius: 8px;
        width: 1000px;
        padding: 40px 40px;
    }

    [hidden] {
        display: none;
    }

    .accordion__button:before {
        position: absolute;
        content: '+';
        right: 20px;
        font-size: 20px;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        content: '-';
    }
    .accordion {
        margin-top: 20px;
        position: relative;
    }

    .accordion__button {
        background: #d8eaff;
        border-radius: 8px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: $heading-color;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .accordion__item {
        margin-bottom: 15px;
    }

    .accordion__panel {
        animation: fadein 0.35s ease-in;
        padding: 10px 20px;
        background: rgba(239, 242, 249, 0.5);
    }

    .add-agent-list-heading {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: $heading-color;
        .heading-left {
            width: calc(100% - 280px);
            padding-right: 20px;
            display: inline-block;
        }
        .heading-center {
            text-align: center;
            width: 140px;
            display: inline-block;
        }
        .heading-right {
            text-align: center;
            width: 140px;
            display: inline-block;
        }
    }
    .add-agent-list-heading-lto-office {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: $heading-color;
        .heading-left {
            width: calc(100% - 240px);
            padding-right: 20px;
            display: inline-block;
        }
        .heading-center {
            text-align: center;
            width: 140px;
            display: inline-block;
        }
        .heading-right {
            text-align: center;
            width: 100px;
            display: inline-block;
        }
        table{
            background: transparent;
            thead, tbody{
                tr{
                    background: transparent;
                    th, td{
                        &:first-child{
                            padding-left: 0px !important;
                            width: 52%;
                        }
                        &:last-child{
                            padding-right: 0px !important;
                        }
                    }
                }
            }

        }
        
    }
    .select-service-list-lto-office {
        li {
            border-bottom: 1px solid $border-color;
            padding-bottom: 10px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            &:last-child {
                border-bottom: 0px;
            }
        }
        .select-service-heading {
            width: calc(100% - 240px);
            padding-right: 20px;
            .custom-control-label {
                font-size: 13px;
            }
        }
        .enter-agent-fee {
            text-align: center;
            width: 140px;
            display: inline-block;
            .material {
                input {
                    &.form-input {
                        height: 34px;
                    }
                }
            }
        }
        .group-fee {
            text-align: center;
            width: 100px;
            display: inline-block;
            .material {
                // input {
                //     &.form-input {
                //         height: 34px;
                //     }
                // }
            }
        }
    }

    .select-service-list {
        li {
            border-bottom: 1px solid $border-color;
            padding-bottom: 10px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            &:last-child {
                border-bottom: 0px;
            }
        }
        .select-service-heading {
            width: calc(100% - 160px);
            padding-right: 20px;
            .custom-control-label {
                font-size: 13px;
            }
        }
        .enter-agent-fee {
            text-align: center;
            width: 160px;
            display: inline-block;
            .material {
                input {
                    &.form-input {
                        height: 34px;
                    }
                }
            }
        }
    }
    .multiselect-dropDown .css-48ayfv + div {
        width: 100%;
    }
}

.show-agnet-popup {
    .modal-main {
        border-radius: 8px;
        width: 700px;
        padding: 40px 40px;
    }

    .modal {
        .modal-body {
            margin-left: -40px;
            margin-right: -40px;
        }
    }

    [hidden] {
        display: none;
    }

    .add-agent-list-heading {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: $heading-color;
        .heading-left {
            width: calc(100% - 160px);
            padding-right: 20px;
            display: inline-block;
        }
        .heading-right {
            text-align: center;
            width: 160px;
            display: inline-block;
        }
    }
    .add-agent-list-heading-lto-office {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: $heading-color;
        .heading-left {
            width: calc(100% - 240px);
            padding-right: 20px;
            display: inline-block;
        }
        .heading-center {
            text-align: center;
            width: 140px;
            display: inline-block;
        }
        .heading-right {
            text-align: center;
            width: 100px;
            display: inline-block;
        }
    }
}

.slider-arrow-view-doc {
    ul {
        li {
            position: absolute;
            top: 50%;

            &.icn-pre-slide {
                left: 10px;
            }
            &.icn-next-slide {
                right: 10px;
            }
        }
    }
}

.doc-view-gallery-popup {
    .modal-main {
        max-width: 100%;
        .single-select {
            width: 100%;
        }
    }
    @media screen and (max-width: 767px) {
        .modal-body {
            width: 100% !important;
            margin-left: 0px !important;
            padding: 0px !important;
        }
    }
}

.acceptance_page_logo {
    display: flex;
    justify-content: center;
    img {
        max-width: 140px;
    }
}

.filter-offices {
    width: 100% !important;
    padding: 10px 0px;

    .search-bx {
        width: 100%;
    }
}

.lto-listing {
    max-height: 320px;
    overflow-y: auto;
    width: calc(100% + 40px);
    margin: 0px -20px;
    padding: 0px 20px;

    li {
        display: block;
        padding: 15px 0px;
        border-bottom: 1px solid $border-color;
    }
}
