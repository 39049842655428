.btn-exceptional {
    min-width: auto !important;
    padding: 0px 20px;
}

.exception-popup {
    .modal-header {
        h2 {
            padding-bottom: 0px !important;
        }
        span {
            padding-bottom: 24px;
            display: block;
        }
    }

    .modal-body {
        overflow-y: auto !important;
    }

    .exceptional-case-list {
        ul {
            padding-bottom: 20px;
            li.custom-checkbox {
                padding-bottom: 15px;
                display: block;
            }
        }
    }
}

.btn-auto-width {
    min-width: auto !important;
    padding: 0px 20px;
}

.dealer-approvaler-outer {
    background: #eff2f9;
    padding: 20px;
    border-radius: 8px;

    .approver-heading {
        display: flex;
        align-items: center;
        h3 {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            color: $heading-color;
        }
        span {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            color: #c70000;
            padding-left: 15px;
        }
    }
    p {
        font-size: 11px;
        font-weight: 400;
        color: $heading-color;
        padding: 15px 0 20px 0;
    }

    .opt-btn {
        display: flex;
        align-items: center;
        button {
            min-width: 120px !important;
            margin-right: 10px;
        }
    }
}

.exceptional-reason-outer {
    h3 {
        font-size: 18px;
        font-weight: 500;
        color: $heading-color;
        padding: 30px 0px 10px;
    }

    .exceptional-reason-list {
        ul {
            li {
                padding: 15px 0;
                border-bottom: 1px solid $border-color;
                label {
                    font-size: 14px;
                    font-weight: 500;
                    color: $heading-color;
                    position: relative;
                    display: block;
                    &::before {
                        content: '';
                        background: $heading-color;
                        height: 4px;
                        width: 4px;
                        border-radius: 100%;
                        display: inline-block;
                        margin: 0px 7px;
                        position: relative;
                        top: -2px;
                    }
                    &::after {
                        content: '\e919';
                        font-family: dc;
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 20px;
                    }
                }

                .selected-chips {
                    ul {
                        padding-top: 5px;
                        padding-left: 20px;
                        li {
                            padding: 6px 6px 6px 17px;
                        }
                    }
                }
                &:last-child {
                    border-bottom: 0px;
                }
                &:first-child {
                    label {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.exceptional-comments {
    background: #fcfae6;
    border-radius: 8px;
    padding: 20px;
    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: $heading-color;
        display: block;
        padding-bottom: 8px;
    }
    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $heading-color;
    }
}

.tl-waiting-outer {
    background: #eff2f9;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .approver-heading {
        h3 {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            color: $heading-color;
        }
        p {
            font-size: 11px;
            font-weight: 400;
            color: $heading-color;
        }
    }
}

.tl-approved-outer {
    background: #ddf7ec;
    border: 1px solid #80cbab;
    padding: 20px;
    border-radius: 8px;

    .approver-heading {
        h3 {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            color: $heading-color;
        }
        i {
            background: #19c463;
            height: 24px;
            width: 24px;
            color: $white;
            border-radius: 24px;
            font-size: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    .tl-comment {
        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: $heading-color;
            display: block;
            padding-bottom: 8px;
            padding-top: 30px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: $heading-color;
        }
    }
}

.pending-tag {
    background: #fa9119;
    width: 63px;
    height: 22px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 500;
    line-height: 50px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 1px;
}

.non-approve-tag {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #00aac9;
    i {
        font-size: 20px;
    }
}

.mark-aaproved-tag {
    @extend .button;
    background: #f4f6fb;
    border: 1px solid #dadada;
    height: 48px;
    font-size: 13px;
    font-weight: 500;
    color: #00a8c6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px -20px -20px -20px;
    width: calc(100% + 40px);
    border-radius: 0 0 8px 9px;
    cursor: pointer;
}

.change-agent-btn {
    border: 1px solid #ff4141 !important;
    color: #ff4141 !important;
    &:hover {
        background: #ff4141 !important;
        color: $white !important;
    }
}

.btn-reject {
    border: 1px solid #ff4141 !important;
    color: #ff4141 !important;
    &:hover {
        background: #ff4141 !important;
        color: $white !important;
    }
    &:disabled,
    &[disabled] {
        color: $heading-color !important;
        border-color: #919191 !important;
        box-shadow: none;
        &:hover {
            color: $heading-color !important;
            border-color: #919191 !important;
            background-color: #919191 !important;
        }
    }
}

.select-agent-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select-agent-card {
    border: 1px solid #dadada;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;

    .card-heading {
        font-size: 14px;
        font-weight: 500;
        color: $heading-color;
    }
    ul {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        li {
            padding-right: 10px;
            white-space: nowrap;
            label {
                font-size: 11px;
                font-weight: 400;
                color: $heading-color;
                display: block;
            }
            span {
                font-size: 13px;
                font-weight: 500;
                color: $heading-color;
            }
        }
    }
    p {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        padding-top: 20px;
    }

    input {
        width: 70px;
        padding: 0px 10px;
        border: 1px solid $border-color;
    }
}

.changes-agent-card {
    background: #fdf4e2;
    border-radius: 10px;
    padding: 0px;

    .change-agent-heading {
        background: #f4e7ce;
        border-radius: 10px 10px 0 0;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: $heading-color;
        }
        label {
            font-size: 11px;
            font-weight: 400;
            line-height: 30px;
            color: #666666;
        }
    }

    .select-agent-card {
        border: 0px;
        border-radius: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-top: 0px;

        .agent-card-heading {
            justify-content: space-between;
            align-items: center;
        }

        .card-heading {
            display: flex;
            align-items: center;
        }

        .agent-show-hide-txt {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: #00aac9;
            cursor: pointer;
        }
    }

    .lto-code-txt {
        color: $heading-color;
        padding-top: 20px;
        padding-bottom: 20px;
        span {
            font-size: 11px;
            font-weight: 400;
            line-height: 16.5px;
            display: block;
        }
        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
        }
    }
}

.new-tag {
    background: #0bbddd;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 500;
    color: $white;
    height: 19px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.old-tag {
    background: #fc6c6c;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 500;
    color: $white;
    height: 19px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.lto-add-status-btn {
    button {
        font-size: 11px;
        padding: 0px 10px !important;
        &.change-agent-btn {
            margin-right: 8px;
        }
    }
}

.agent-list-popup {
    .modal-body {
        max-height: calc(100vh - 220px);
    }
}

.agent-detail-progress-outer {
    max-height: 300px;
    overflow-y: auto;
}

.assign-agnet-list {
    max-height: calc(100vh - 290px);
    overflow-y: auto;
    span {
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        color: rgba($heading-color, 0.4);
    }
    ul {
        padding-top: 20px;
        li {
            padding-bottom: 15px;
        }
    }
}

.select-agent-btn {
    border-top: 1px solid $border-color;
    display: block;
    text-align: center;
    padding-top: 10px;
    margin-top: 10px;

    .span-disabled {
        margin-bottom: 0px;
    }
}

.change-agent-lto-popup {
    height: 400px;

    .body {
        min-height: 200px;
    }

    .second {
        height: 300px;
        fieldset {
            height: 300px;
        }
    }
}

.sta-multi-select {
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    padding: 10px 35px 10px 10px;
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    i {
        position: absolute;
        right: 10px;
        font-size: 25px;
        font-weight: 500;
    }
    p {
        font-size: 12px;
    }
}

.single-agnet-charge-box {
    .agent_charges {
        margin-bottom: 0px !important;
    }
}

.select-single-agnet-heading {
    h3 {
        padding: 10px 0 !important;
    }
}

.agent-charge-box {
    .select-agent-heading {
        display: block;
        width: 100%;
        h3 {
            font-size: 16px;
        }
    }
}

.select-agnet-tl-aprove-txt {
    padding: 10px 20px;
    text-align: center;
    h3 {
        font-size: 12px;
        color: #d62828;
    }

    .ic-access_timequery_builderschedule {
        margin-right: 5px;
    }
}

.m-minus-30 {
    margin-left: -30px;
}

.multi-agent-listing {
    .standard-charge-view {
        display: flex;
        justify-content: space-around;
        background: #fdf4e2;
    }

    .agent-box {
        border: 2px solid lightgray;
        padding: 8px 12px;
        margin: 12px 0px;

        .agent-name {
            color: #000000;
            font-size: 14px;
        }

        .service-tat-view {
            display: flex;
            justify-content: space-between;

            .single-entity {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.select-agents-popup,
.confirm-agents-popup {
    .modal-body {
        max-height: 450px;
        overflow-y: hidden;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        width: 530px;
        padding: 40px 40px;
        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }

    .agent-listing {
        max-height: 320px;
        overflow-y: auto;
        width: calc(100% + 40px);
        margin: 0px -20px;
        padding: 0px 20px;
        li {
            display: block;
            padding: 15px 0px;
            border-bottom: 1px solid $border-color;
        }

        .agent-heading {
            padding: 20px 0px;
            font-size: 18px;
        }
    }
}

.confirm-agents-popup {
    .modal-main {
        width: 350px;

        .modal-body {
            width: 100%;
            margin: 0px;
            padding: 0px;
        }
    }
}

.select-agent-box {
    width: 50%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border: 2px solid lightgray;
    border-radius: 8px;
}

.tl-waiting-box-outer {
    border: 1px solid #eba9a9;
    border-radius: 8px;
    background: #ffe7e7;
    padding: 25px 20px;

    .ic-access_timequery_builderschedule {
        color: #d62828;
        margin-left: 5px;
    }
    span {
        font-size: 11px;
        font-weight: 400;
        color: $heading-color;
    }
    h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        color: $heading-color;
    }
}

.select-agent-outer {
    h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        color: $heading-color;
    }

    .agent-charges {
        background: #fdf4e2;
        border-radius: 8px;
        padding: 15px 20px;
        margin-top: 20px;

        .agnet-charges-txt {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            width: 50%;
            color: $heading-color;
            display: inline-block;
            label {
                font-weight: 700;
            }
        }
    }

    .select-agent-list {
        padding-top: 20px;
    }

    .tl-approve-bg {
        background: #19c463;
        border-radius: 8px 8px 0 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        margin: -20px -20px 20px -20px;
        width: calc(100% + 40px);
    }
}

.changes-agent-card {
    background: #fdf4e2;
    border-radius: 10px;
    padding: 0px;

    .change-agent-heading {
        background: #f4e7ce;
        border-radius: 10px 10px 0 0;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: $heading-color;
        }
        label {
            font-size: 11px;
            font-weight: 400;
            line-height: 30px;
            color: #666666;
        }
    }

    .select-agent-card {
        border: 0px;
        border-radius: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-top: 0px;

        .agent-card-heading {
            justify-content: space-between;
            align-items: center;
        }

        .card-heading {
            display: flex;
            align-items: center;
        }

        .agent-show-hide-txt {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: #00aac9;
            cursor: pointer;
        }
    }

    .lto-code-txt {
        color: $heading-color;
        padding-top: 20px;
        padding-bottom: 20px;
        span {
            font-size: 11px;
            font-weight: 400;
            line-height: 16 0.5px;
            display: block;
        }
        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
        }
    }
}

.new-tag {
    background: #0bbddd;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 500;
    color: $white;
    height: 19px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.old-tag {
    background: #fc6c6c;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 500;
    color: $white;
    height: 19px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lto-add-status-btn {
    button {
        font-size: 11px;
        &.change-agent-btn {
            margin-right: 8px;
        }
    }
    button:disabled,
    button[disabled] {
        color: $heading-color !important;
        border-color: #919191 !important;
        box-shadow: none;
        &:hover {
            color: $heading-color !important;
            border-color: #919191 !important;
            background-color: #919191 !important;
        }
    }
}

.assign-agnet-list {
    span {
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        color: rgba($heading-color, 0.4);
    }
    ul {
        padding-top: 20px;
        li {
            padding-bottom: 15px;
        }
    }
}

.tl-reject-outer {
    background: #fdafaf;
    border: 1px solid #ee3b3b;
    padding: 20px;
    border-radius: 8px;
    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .approver-heading {
        h3 {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            color: $heading-color;
        }
        i {
            background: #c41919;
            height: 24px;
            width: 24px;
            color: $white;
            border-radius: 24px;
            font-size: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    .tl-comment {
        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: $heading-color;
            display: block;
            padding-bottom: 8px;
            padding-top: 30px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: $heading-color;
        }
    }
}
