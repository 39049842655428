.link-button {
    background: none;
    border: none;
    color: black; /* or any color you want for the link */
    text-decoration: underline; /* underline to simulate link */
    cursor: pointer; /* show pointer cursor on hover */
    padding: 0; /* optional: remove padding to make it more compact */
    font: inherit; /* optional: inherit font from parent */
}

.link-button:hover {
    text-decoration: none; /* remove underline on hover if desired */
}

.link-button[disabled] {
  border: none; /* Remove default button border */
  background: none; /* Remove default button background */
  color: #999; /* Change text color to indicate it's disabled */
  text-decoration: underline; /* Underline text to mimic link */
  cursor: default; /* Change cursor to default to indicate it's not clickable */
  padding: 0; /* Remove padding to make text closer to link appearance */
  outline: none; /* Remove outline */
}

.cross-icon {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
    background-color: #ccc; /* Gray background */
    color: #fff; /* White color */
    border-radius: 50%; /* Make it round */
    position: relative;
    position: absolute;
    top: 10px; /* Adjust top position */
    right: 10px; /* Adjust right position */
    cursor: pointer;
  }

  .cross-icon::before,
  .cross-icon::after {
    content: '';
    position: absolute;
    width: 70%;
    height: 2px;
    background-color: #fff; /* White color */
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .cross-icon::before {
    transform: rotate(45deg);
  }

  .cross-icon::after {
    transform: rotate(-45deg);
  }

  /* Hover effect */
  .cross-icon:hover::before,
  .cross-icon:hover::after {
    background-color: #eee; /* Lighter gray on hover */
  }

  .cross-icon:hover {
    transform: scale(1.1); /* Scale up slightly on hover */
    background-color: #999; /* Darker gray background on hover */
  }

  .cross-icon:hover::before,
  .cross-icon:hover::after {
    background-color: #fff; /* White lines on hover */
  }

  .cross-icon:hover {
    color: #000; /* Black text color on hover */
  }

  .btn-download-tb{
    text-align: center !important;
  }

  .pdf-img-orcr{
    font-size: 125px !important;
  }