.ocr-doc-upload-outer {
  .ocr-bg-inner {
    box-shadow: 0px 2px 4px 0px #0000000D;
    background: $white;
    border-radius: 10px;
    padding: 20px;
  }

  .ocr-upload-content {
    .doc-type-name {
      font-weight: 500;
      color: $heading-color;
      font-size: 13px;
    }

    .grid-item-list .col-md-4 {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .ocr-upload-right-sec {
    height: 100%;
    .accordion__item {
      margin-bottom: 15px;
    }

    .accordion__heading {
      background: #F0F2F8;
      border-radius: 10px;
      font-size: 18px;
      font-weight: 500;
      color: $heading-color;
      padding: 10px 20px;
      cursor: pointer;
      position: relative;
    }

    .accordion__button {
      &::after {
        content: "\e907";
        font-family: 'dc' !important;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .accordion__button[aria-expanded='true']::after,
    .accordion__button[aria-selected='true']::after {
      content: "\e90c";
      font-family: 'dc' !important;
    }

    fieldset {
      margin-top: 15px;
    }

    .chip-control {
      position: relative;

      span {
        border: 1px solid #D7D7D7;
        width: 150px;
        background: $white;
        border-radius: 50px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: $heading-color;
        cursor: pointer;

      }

      input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        top: 0;
      }

      &.chip-verfied {
        input:checked~span {
          background: #18CF82;
          border-color: #18CF82;
          color: $white;
        }
      }

      &.chip-not-verfied {
        input:checked~span {
          background: #FF4C4C;
          border-color: #FF4C4C;
          color: $white;
        }

      }
    }
    .chip-btn-outer{
      display: flex;
      align-items: center;
    }
  }

  .upload-proof-txt {
    position: relative;

    label {
      color: #00A3C0;
      font-size: 13px;
      font-weight: 500;
      line-height: 30px;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

}

.d-block {
  display: block;
}