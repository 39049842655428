@import 'brandcolor';
.radio-type-txt {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .form-label {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        margin-right: 30px;
        color: $heading-color;
    }

    .custom-checkbox {
        margin-right: 35px;
    }
}

.form-tab-right-panel {
    h2 {
        padding-bottom: 23px;
    }

    fieldset {
        margin-bottom: 17px;

        &.text-area-filed {
            margin-bottom: 14px;
        }
    }

    .row {
        margin-right: -10px;
        margin-left: -10px;

        .col-md-6,
        .col-md-12 {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.agent-charge-box {
    background: #eff2f9;
    padding: 20px;
    margin: 10px 0 0;
    border-radius: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    .agent_charges {
        width: 49%;
        margin-bottom: 15px;
        .label {
            width: 140px;
            display: inline-block;
        }
    }
    .final_charges_input {
        display: flex;
        align-items: center;
        .label {
            min-width: 110px;
            width: 110px;
        }
        input {
            width: 100%;
        }
    }

    .label {
        color: #000000;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        white-space: nowrap;
    }

    .value {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }
}

.acceptance-link {
    background: #eff2f9;
    .button {
        background: $accent-color;
        box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
        border-radius: 40px;
        // height: 44px;
        width: 100px;
        line-height: 45px;
        font-weight: 500;
        font-size: 14px;
        color: $white;
        cursor: pointer;
        // min-width: 200px;
        border: 1px solid $accent-color;
    }

    .value {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }
}

.btn-save-remarks {
    .btn-primary {
        min-width: 177px;
        margin-right: 10px;
        margin-bottom: 10px;

        &.cancel-btn {
            min-width: 240px;
        }
    }

    .btn-line {
        min-width: 125px;
    }

    .btn-disable {
        background: #919191;
        border-color: #919191;
        box-shadow: none;
    }
}

.btn-calculate-manual {
    display: flex;
    position: relative;
    top: 3px;
    left: -8px;

    .btn-line {
        min-width: 100px;
        margin-left: 6px;
        color: #6ca8b3;
        border: none;
        box-shadow: 0px 5px 6px -1px #5c767a;
    }

    .btn-disable {
        background: #919191;
        color: black;
        border-color: #919191;
        box-shadow: none;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;

    .modal-main {
        position: fixed;
        background: white;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close_icn {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 21px;
        cursor: pointer;
    }

    .modal-body {
        max-height: 450px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }
}

.single-select {
    .react-select__placeholder {
        margin-right: 2px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
    }

    .react-select__value-container--has-value .css-1wa3eu0-placeholder,
    .react-select__value-container--has-value .react-select__placeholder {
        top: -9px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .react-select__control--menu-is-open .css-1wa3eu0-placeholder,
    .react-select__control--menu-is-open .react-select__placeholder {
        top: -9px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .css-g1d714-ValueContainer,
    .react-select__value-container,
    .react-select__value-container--has-value {
        overflow: inherit !important;
        background: $white;
        padding-left: 13px;
        padding-right: 13px;
    }

    .css-26l3qy-menu {
        margin-top: 0px;
    }

    .is-diabled--is-disabled {
        .is-diabled__value-container,
        .is-diabled__control--is-disabled {
            background-color: #f8f8f8;
            min-height: 42px;
            border-radius: 8px;
        }
    }
}

.label-txt-select {
    width: 100% !important;
}

.lead-form-filed {
    padding-top: 5px !important;

    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        padding: 25px 0px 25px;
        color: $heading-color;
    }
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.doument-gallery-tabs {
    .nav-pills {
        width: 100%;
        margin-left: 0px;
        box-shadow: none;
        padding: 0px;
        padding-right: 0px;
        justify-content: flex-start;

        .nav-item {
            a {
                background: transparent;
                padding: 0px;
                height: auto;
                border-bottom: 2px solid transparent;
                border-radius: 0px;
                color: rgba($heading-color, 0.5);
                margin-right: 35px;
                padding-bottom: 3px;

                &.active {
                    border-color: #0bbddd;
                    color: #0bbddd;
                    font-weight: 500;
                }
            }

            &:last-child {
                a {
                    margin-right: 0px;
                }
            }
        }
    }

    .tab-content {
        width: 100%;
        margin-left: 0px;
        padding-right: 0px;
    }

    .tab-list {
        position: relative;
        width: 100%;
        margin-bottom: 20px;

        &:after {
            content: '';
            background: rgba($heading-color, 0.1);
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0px;
        }
    }
}

.doc-section-headers {
    overflow-x: scroll;

    a {
        margin-right: unset !important;
        width: 150px;
    }
}

.doc-section-headers::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

.file-submit-selet-list {
    display: flex;
    width: 100%;
    align-items: center;

    fieldset {
        width: 100%;
    }

    .grid-icn {
        .ic-gridview {
            font-size: 15px;
        }
    }
}

.lead-doc-count {
    background: #0bbddd;
    border-radius: 55px;
    height: 18px;
    padding: 0px 8px;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 13%;

    span {
        font-weight: 500;
        color: $heading-color;
        padding-left: 5px;
    }
}

.doc-count {
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    color: #000000;
    display: block;
    padding: 10px 0;

    span {
        font-size: 14px;
        font-weight: 400;
    }
}

.verfication-img {
    padding: 50px 0;

    img {
        width: 100%;
        max-height: 350px;
    }
}

.image-opt {
    position: absolute;
    top: 41%;
    width: 100%;
    left: 0;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    padding: 0px 15px;
    width: 100%;

    &.no-doc-btn {
        bottom: 60px;
        top: inherit;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        li {
            padding: 0px 5px;

            button {
                background: #ffffff;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
                border-radius: 0px;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 20px;
                border-width: 0px;
                cursor: pointer;
            }

            .ic-history {
                font-size: 17px;
            }
        }
    }

    &.doc-image-opt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        ul {
            margin-bottom: 0px;
        }

        .btn-submission-approved {
            justify-content: flex-end;
        }
    }

    &.doc-image-opt {
        top: inherit;
        left: 0;
        right: 0px;
        margin: 0 auto;
        text-align: center;
        bottom: 60px;

        .btn-submission-approved {
            justify-content: center;
        }
    }
}

.lead-form-heading {
    position: relative;

    .main-heading {
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }
}

.gird-view-select-opt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.image-bx {
    background: #f4f6fb;
    border-radius: 6px;
    padding: 10px;
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 100%;
    overflow: hidden;

    .ic-pdf-file {
        font-size: 60px;
    }
}

.grid-item-list {
    .row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .col-md-4,
    .col-md-12 {
        padding-right: 10px;
        padding-left: 10px;
        overflow: hidden;
    }

    .img-bx-select-opt {
        margin-top: 20px;
    }
}

.check-box-small {
    .custom-control-label {
        padding-left: 26px;
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        color: rgba($heading-color, 0.5);

        &:before {
            height: 14px;
            width: 14px;
            border: 1px solid rgba($heading-color, 0.5);
        }

        &:after {
            top: -1px;
            width: 16px;
            height: 16px;
            left: -1px;
        }
    }

    .custom-control-input {
        width: 18px;
        height: 18px;
    }

    &.select-check-bx {
        .custom-control-label {
            padding-left: 26px;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: rgba($heading-color, 1);
        }
    }
}

.btn-line-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    position: absolute;
    bottom: 10px;
    right: 15px;

    .btn-line {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        height: 34px;
        line-height: 34px;
        padding: 0px 10px;
        margin-left: 8px;
        border-radius: 6px;
        min-width: auto;
    }
}

.list-icn {
    position: relative;
    top: -4px;
    font-size: 17px;
}

.info-icn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
}

.p-r-30 {
    padding-right: 30px !important;
}

.po-upload-img {
    margin-top: 20px;

    &.Add-Financer-popup {
        .img-bx-select-opt {
            .image-bx {
                height: auto;
                flex-direction: row;
            }
        }

        .more-file-upload {
            img {
                height: 22px;
                margin-right: 10px;
            }

            input[type='file'] {
                height: 100%;
            }
        }
    }
}

.upload-preview {
    display: block;
    width: 100%;
    margin-top: 20px;

    .img-preview-bg {
        background: #eff2f9;
        border-radius: 8px;
        height: 260px;
        position: relative;
        padding: 10px;
        overflow: hidden;
        text-align: center;
        margin-top: 0 auto;

        img {
            height: 100%;
        }

        i {
            position: absolute;
            right: 10px;
            top: 10px;
            background: rgba(31, 31, 31, 0.6);
            height: 16px;
            width: 16px;
            color: $white;
            border-radius: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .reupload-txt {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        text-decoration: underline;
    }
}

.skip-txt {
    position: absolute;
    right: 0px;
    top: 5px;
    text-decoration: underline;
    cursor: pointer;
}

.error-msg,
.error-msg-txt {
    font-size: 12px;
    color: red;
}

.image-varification-slider {
    .pfd-img-height {
        > div {
            > div {
                display: flex;
                top: inherit !important;
                bottom: 20px;
                background: transparent !important;
                box-shadow: none !important;
                border: 0px !important;
                right: 0px !important;
                width: 100%;
                align-items: center;
                justify-content: center;

                > div {
                    border-radius: 100%;
                    background: $white;
                    width: 30px !important;
                    height: 30px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0px 5px;
                    border-bottom: 1px solid rgb(204, 204, 204);

                    svg {
                        height: 18px !important;
                        width: 18px !important;
                        padding: 0px !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;

                        path,
                        polygon {
                            stroke: #000;
                        }
                    }
                }
            }
        }

        .pan-container {
            > div {
                height: auto !important;
                width: auto !important;
                margin: 0px !important;
            }
        }
    }

    .image-opt {
        //   left: 0px;
        padding: 0px 20px 0px 25px;

        .icn-pre-slide {
            position: absolute;
            left: 0px;
            top: 50%;
        }

        .icn-next-slide {
            right: 0px;
            position: absolute;
            top: 50%;
        }
    }
}

.pan-container {
    height: 350px !important;
    overflow: hidden;
}

.carousel__slide {
    display: none;

    &.carousel__slide--active {
        display: block;
    }
}

.pdf-icons-normal-gallery {
    font-size: 283px;
    float: left;
    width: 100%;
    text-align: center;
}

.pdf-icons-small-gallery {
    font-size: 110px;
    float: left;
    width: 100%;
    text-align: center;
}

.info-icn-lead-details {
    position: absolute;
    top: 17px;
    right: 10px;
    cursor: pointer;
}

.react-select--is-disabled {
    .react-select__value-container,
    .css-1fdsijx-ValueContainer {
        background: transparent !important;
    }
}

.acco-doc-list {
    ul {
        li {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba($heading-color, 0.1);
            padding: 10px 0;

            &:first-child {
                padding-top: 0px;
            }

            &:last-child {
                border-bottom: 0px;
            }
        }
    }

    .doc-count-txt {
        display: flex;
        align-items: center;

        .lead-doc-count {
            width: 50px;
        }
    }

    .doc-upload-btn {
        position: relative;
        cursor: pointer;

        input[type='file'] {
            opacity: 0;
            height: 100%;
            width: 100%;
            outline: 0;
            position: absolute;
            cursor: pointer;
            left: 0;
        }
    }

    .btn-line {
        display: flex;
        align-items: center;
        justify-content: center;

        .ic-upload {
            font-size: 20px;
        }
    }
}

.stepprogress {
    color: #31b93e;
    font-size: 16px;
    font-weight: bolder;

    .ic-check {
        font-weight: 800;
    }
}

.split-act-btn {
    display: flex;
    align-items: center;

    // justify-content: space-between;
    .action-btn {
        display: flex;
        align-items: flex-end;
        margin-left: auto;

        li {
            height: 32px;
            width: 32px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            cursor: pointer;

            &:last-child {
                margin-right: 0px;
            }

            &.btn-edit {
                border-color: $accent-color;
                color: $accent-color;
            }

            &.btn-delete {
                border-color: $promotional-color-2;
                color: $promotional-color-2;
            }
        }
    }
}

input#account_number.form-input,
input#cb_account_number.form-input {
    // -webkit-text-security: disc;
    // font-size: 16px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.resubmit-btn {
    position: absolute;
    width: 115px;
    height: 32px;
    left: 296px;
    top: 52px;
    background: #ffa340;
    border-radius: 4px;
    border: none;
    /* Apply Filter */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.resubmit-icn {
    position: relative;
    top: 2px;
    left: 5px;
}

.comp-cmnt {
    box-sizing: border-box;
    position: relative;
    width: 335px;
    height: auto;
    left: 90px;
    top: -21px;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    padding: 9px;
    font-size: 13px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #ff8a8a;
}

.comp-cmnt-heading {
    position: relative;
    top: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #979797;
}

.Add-Financer-popup {
    .modal-main {
        border-radius: 8px;
        width: 560px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 19px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }

        .modal-body {
            padding-top: 5px;
        }
    }

    .img-bx-select-opt {
        position: relative;
        margin: 0px 0px;

        .image-bx {
            height: 140px;
            margin-bottom: 0px;
        }

        &:hover {
            .close-icn {
                opacity: 1;
            }
        }

        .custom-control-label {
            display: flex;
        }
    }

    .logo-section {
        align-self: flex-end;
    }

    .more-file-upload {
        flex-direction: column;

        p {
            display: block;
            text-decoration: underline;
            font-size: 14px;
            line-height: 30px;
            color: $heading-color;
            text-align: center;
        }

        input[type='file'] {
            opacity: 0;
            height: 140px;
            width: 100%;
            outline: 0;
            z-index: 1;
            position: absolute;
            top: 0;
            cursor: pointer;
        }
    }

    .subheading {
        display: block;
        padding: 30px 0px 16px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: rgba($heading-color, 0.87);
        width: 100%;
    }

    .radio-type-txt {
        flex-wrap: wrap;
    }

    .btn-primary {
        min-width: 230px;
        margin-top: 20px;
    }

    fieldset {
        width: 100%;
    }
}

.label-value-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.label {
    flex: 1;
    text-align: left;
    padding-right: 20px;
    font-weight: 800;
}

.value {
    flex: 1;
    text-align: right;
}

.grid-doc-count {
    display: flex;
    align-items: center;

    h2 {
        padding-right: 3px;
    }
}

.doc-type-name {
    border-radius: 8px;
    background: #d8eaff;
    padding: 10px 20px;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.tag-category-outer {
    position: relative !important;
    right: initial !important;

    button {
        width: 225px !important;
    }

    .tag-main-category {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .remove-tag-txt {
        right: 0px;
        margin-top: 0px;
    }
}

.justify-content-between {
    justify-content: space-between;
}

.upload-tag-photo {
    position: relative;
    cursor: pointer;

    input {
        opacity: 0;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }

    .ic-add {
        font-size: 35px;
    }
}

.text-center {
    text-align: center;
}

.btn-submission-Verified {
    .btn-line {
        min-width: auto;
        padding: 0px 10px;
    }
}

.status-payment-detail-outer {
    padding-left: 30px;

    tbody {
        tr {
            position: relative;

            td {
                vertical-align: top;
                &:first-child {
                    padding-left: 0px;
                    width: 100%;
                }

                &:last-child {
                    padding-right: 0px;
                    width: 100px;
                }
            }

            &::after {
                content: '';
                border-left: 1px solid #bcd6e7;
                left: -20px;
                position: absolute;
                top: 18px;
                height: 100%;
            }

            &::before {
                content: '';
                background: #fff;
                border: 2px solid #bcd6e7;
                height: 8px;
                width: 8px;
                vertical-align: top;
                display: inline-block;
                position: relative;
                border-radius: 50%;
                z-index: 2;
                position: absolute;
                left: -25px;
                top: 16px;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            &:first-child {
                &::before {
                    background: #0bbddd;
                    border: 2px solid #0bbddd;
                }
            }
            &:hover {
                background: transparent;
            }
        }
    }

    .status-list-heading {
        font-size: 14px;
        color: $heading-color;
        font-weight: 500;
        display: block;

        .completed {
            background: $success-color;
            color: $white;
            border-radius: 10px 0px;
            padding: 0px 10px;
        }
        .processing {
            background: $inprocess-color;
            color: $black;
            border-radius: 10px 0px;
            padding: 0px 10px;
        }
        .delayed {
            background: $error-color;
            color: $white;
            border-radius: 10px 0px;
            padding: 0px 10px;
        }
    }
}

.p-l-30 {
    padding-left: 30px;
}

.link-name-url {
    width: 70%;
    .link-name {
        font-size: 13px;
        font-weight: 500;
        color: $heading-color;
    }
    .link-url {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.acceptance-link {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
    border-radius: 8px;
    height: auto;
    padding: 10px 14px;
}

.export-edit-btn {
    display: flex;
    .btn-line {
        min-width: auto;
        padding: 0px 15px;
        i {
            font-size: 20px;
            margin-right: 5px;
        }
    }
}
.overflow-h {
    overflow: hidden;
}

.btn-submission-approved,
.btn-submission-Verified {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .btn-line {
        min-width: auto;
        padding: 0px 10px;
        height: 34px;
        line-height: 31px;
        font-size: 12px;
        font-weight: normal;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.btn-accept-reject {
    .btn-primary {
        margin-right: 0px !important;
        min-width: auto !important;
        padding: 0px 30px;
    }
}
.url-btn {
    .button {
        height: 26px;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        padding: 0px 10px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
