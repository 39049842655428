$bodycolor: #f1f3f6;
$bodywhite: #ffffff;
$headerbg: #1e335e;
$primary-color: #e46536;
$blue-color: #2176ae;
$black: #000000;
$white: #ffffff;
$error-color: #e24141;
$success-color: #31b93e;
$inprocess-color: #e0c83f;

$opacity-12: 0.12;
$opacity-37: 0.37;
$opacity-54: 0.54;
$opacity-87: 0.87;

$black-3: rgba($black, 0.03);
$black-12: rgba($black, 0.12);
$black-37: rgba($black, 0.37);
$black-54: rgba($black, 0.54);
$black-87: rgba($black, 0.87);

$white-12: rgba($white, 0.12);
$white-37: rgba($white, 0.37);
$white-54: rgba($white, 0.54);
$white-87: rgba($white, 0.87);
