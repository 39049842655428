.popup-outer {
    // background: rgba($heading-color, 0.5);
    // position: fixed;
    // top: 0%;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
}
.add-role-popup {
    // width: 500px;
    // background: $white;
    padding: 40px;
    border-radius: 15px;
    textarea {
        border: 1px solid rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 15px;
        font-weight: 500;
        font-size: 13px;
        height: 120px;
    }
    fieldset {
        padding: 5px;
        border: 0px;
    }
}
